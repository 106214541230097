import { ACS_LOCATION } from './../../../../models/Location';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { AfterViewChecked, AfterViewInit, Component, OnDestroy, OnInit, HostListener } from '@angular/core';
import { Store } from '../../../../store/store.module';
import {
  UPDATE_PRODUCT_REQUESTED,
  DELETE_PRODUCT_REQUESTED,
  RENDER_EDIT_PRODUCT_FORM_REQUESTED,
  EDIT_PUBLISH_PRODUCT_REQUESTED,
  UPDATE_MARKETPLACE_INFORMATION_REQUESTED,
  UPDATE_STATUS_PRODUCT_REQUESTED,
  CANCEL_SALE_ON_PRODUCT_REQUESTED,
  PRODUCT_DATA_MODIFIED,
  DELIST_PRODUCT_FROM_MARKETPLACE_REQUESTED,
  RESOLVE_PRODUCT_FROM_MARKETPLACE_REQUESTED,
  MARK_PRODUCT_AS_FAIL_FROM_MARKETPLACE_REQUESTED,
  SAVE_SMART_PRICER_COMMENT_REQUEST,
  SAVE_COMMENT_FROM_SMART_PRICER,
  GET_PRODUCT_ITEM_BY_PRODUCT_ID_SUCCESSED,
  GET_PRODUCT_REQUESTED,
  CREATE_PRODUCT_ORDER_WITHOUT_STOCK_QUANTITY_REQUESTED,
  GET_PRODUCT_ITEM_BY_PRODUCT_ID_REQUESTED,
  FETCH_ALL_STATUS_REQUESTED,
  CLONE_PRODUCT_REQUESTED,
  GET_ALL_PRODUCT_MANAGEMENT_BY_PRODUCT_REQUESTED,
  GET_PRODUCT_CLONE_HISTORY_REQUESTED,
  UPDATE_FORM_INPUT
} from './edit.actions';
import {
  InputBase,
  TextBox,
  Dropdown,
  TextArea,
  TextBoxMask,
  Select2,
  TypeAheadWithoutApi,
  CustomUploadImages,
  ImageGallery,
  UPLOAD_DIRECTIVE_HEADERS,
  UPLOAD_DIRECTIVE_API_URL,
  ImageZoom,
  Radio,
  TypeAhead
} from '@reflaunt/reactive-form';
import { pickBy, identity, isNaN, isUndefined, assign, isEmpty, isArray, find, isNil, max, min, isBoolean, orderBy, toUpper } from 'lodash';
import { AppInjector } from '../../../../app-injector';
import { Validators } from '@angular/forms';
import { listRouter, getSellingPrice, stepsCreateCollection, historyRouter } from '../product.const';
import { QueryParser } from '../../../../common/util/query-parser';
import { USER_ROLES } from '../../../../app.const';
import { NotificationService } from '../../../../common/services/notification/notification.service';
import * as $ from 'jquery';
import { fromEvent, Subscription, timer } from 'rxjs';
import { Block } from '@reflaunt/reactive-form';
import { listAdminCollection } from '../../collection/collection.const';
import { editAdminOrder, detailAdminOrder, editAdminOrderProduct } from '../../order/order.const';
import { editAdminShipment, detailAdminShipment } from '../../shipment/shipment.const';
import { filter, map, take } from 'rxjs/operators';
import { environment } from '../../../../../environments/environment';
import * as Cookies from 'js-cookie';
import { ApiService } from '../../../../api/api.service';
import { BASH_RETAILER_EMAIL } from './edit.const';
import { ngSelectOnCompare } from '../../attributes/attributes.const';
import Swal from 'sweetalert2';
import { debounceTime } from 'rxjs/operators';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ShowUserComponent } from '../sold-product-modal/sold-product-modal.component';
import { ORDER_TYPES } from '../../../../models/Order';
import { ORDER_ITEM_STATUS_CANCELED, ORDER_ITEM_STATUS_RETURNED, ORDER_ITEM_STATUS_RETURN_COMPLETE, ORDER_ITEM_STATUS_SOLD } from '../../../../models/OrderProduct';
import * as JSZip from 'jszip';
import * as moment from 'moment';
import { saveAs } from 'file-saver';
import { GenderMapping } from '../../../../models/Size';

@Component({
  selector: 'app-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.scss'],
  providers: [
    {
      provide: UPLOAD_DIRECTIVE_HEADERS,
      useFactory: () => {
        return { Authorization: 'Bearer ' + Cookies.get(environment.jwtTokenKey) };
      }
    },
    {
      provide: UPLOAD_DIRECTIVE_API_URL,
      useFactory: () => {
        return environment.apiUrl;
      }
    }
  ]
})
export class EditComponent implements OnInit, OnDestroy {
  cancelReasons = [{ value: 'item not accepted' }, { value: `condition doesn't match` }, { value: 'item quality issue' }, { value: 'material not accepted' }];
  constructor(private router: Router, private route: ActivatedRoute, store: Store, private queryParser: QueryParser, private api: ApiService, private modalService: NgbModal) {
    this.store = store.getInstance();
    this.subscription = this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.switch = false;
        // this.store.dispatch({
        //   type: GET_TRANSACTION_BY_PRODUCT_ID_REQUESTED,
        //   data: assign({}, this.queryParser.parse(['product_id']), { product_id: this.route.snapshot.params.id })
        // });
        // this.store.dispatch({
        //   type: GET_HISTORY_PUSH_BY_PRODUCT_ID_REQUESTED,
        //   data: assign({}, this.queryParser.parse(['product_id']), { product_id: this.route.snapshot.params.id })
        // });

        if (this.route.snapshot.queryParams.collection_id) {
          this.collectionEditProduct = true;
        }
        this.store.dispatch({
          type: GET_PRODUCT_ITEM_BY_PRODUCT_ID_REQUESTED,
          data: {
            product_id: this.route.snapshot.params.id,
            ...pickBy(this.route.snapshot.queryParams, identity)
          }
        });
        this.store.dispatch({
          type: GET_ALL_PRODUCT_MANAGEMENT_BY_PRODUCT_REQUESTED,
          data: {
            product_id: this.route.snapshot.params.id,
            page: this.route.snapshot.queryParams.page ? this.route.snapshot.queryParams.page : 1,
            per_page: this.route.snapshot.queryParams.per_page ? this.route.snapshot.queryParams.per_page : 20
          }
        });
        this.store.dispatch({
          type: GET_PRODUCT_CLONE_HISTORY_REQUESTED,
          data: {
            id: this.route.snapshot.params.id
          }
        });
      }
    });
    this.productId = this.route.snapshot.params.id;
    this.route.queryParams.subscribe((params) => {
      if (params) {
        if (params.referer) {
          this.queryParams.referer = params.referer;
        }
        if (params.highlight) {
          this.queryParams.highlight = params.highlight;
          params.highlight === 'image' ? (this.checkHighlightImage = true) : (this.checkHighlightImage = false);
        }
        if (params.extra_params) {
          this.queryParams.extra_params = params.extra_params;
        }
        if (params.collection_id) {
          this.collectionId = params.collection_id;
          this.api.admin.collection.show(this.collectionId).subscribe((res) => {
            if (res) {
              this.collection = res;
            }
          });
        }
        this.paramImage = { referer: this.queryParams.referer, highlight: 'image', collection_id: this.collectionId };
        this.paramPricing = { referer: this.queryParams.referer, highlight: 'price', collection_id: this.collectionId };
        this.paramProductInfo = { collection_id: this.collectionId, extra_params: this.queryParams.extra_params };
      }
    });
  }
  public store;
  public sold;
  public editForm;
  public from_collection;
  public collectionId;
  public historyRouter = historyRouter;
  public editAdminOrder: any = editAdminOrder;
  public editAdminShipment: any = editAdminShipment;
  public detailAdminShipment: any = detailAdminShipment;
  public detailAdminOrder: any = detailAdminOrder;
  public editAdminOrderProduct: any = editAdminOrderProduct;
  public isDisplayMarketplace = true;
  public subscription: Subscription;
  public switch = false;
  public listRouter = listRouter();
  public listAdminCollection = listAdminCollection();
  public filters = {
    role_ids: USER_ROLES.MARKETPLACE
  };
  public queryParams = {
    referer: '',
    highlight: '',
    referer_url: null,
    extra_params: ''
  };
  public order_types = ORDER_TYPES;
  public collection;
  public warehouse;
  public ngSelectOnCompare = ngSelectOnCompare;
  public inputs: InputBase<any>[];

  public onPriceSubmitRejectedPartial;
  public onPriceSubmitAcceptedPartial;
  public BASH_RETAILER_EMAIL = BASH_RETAILER_EMAIL;

  public baseOriginalPriceObservable;
  public basePayoutPriceObservable;
  public originalPriceObservable;
  public payoutPriceObservable;
  public checkPending;
  public productId;
  public paramImage;
  public paramPricing;
  public paramProductInfo;
  public checkHighlightImage;
  public stepsCreateCollection = stepsCreateCollection;
  public availableAskSellerButtonRetailers = [
    {
      retailer: 'bash@reflaunt.com',
      status: ['pending', 'waiting', 'image-processing']
    },
    {
      retailer: 'clio-goldbrenner@reflaunt.com',
      status: ['pending', 'waiting', 'image-processing']
    },
    {
      retailer: 'monnierfreres@reflaunt.com',
      status: ['pending', 'incomplete']
    }
  ];
  public disableLoadMoreButton = false;
  public collectionEditProduct: Boolean;
  public locationIdParam;
  public statusIdParam;
  public priceParam;
  public currentTotal;
  public checkLoadingRetouch;
  public showLocation = false;
  onChangeReative = (form) => {
    if (this.store.getState().Admin.Product.edit.updatedForm) {
      this.basePayoutPriceObservable = undefined;
      this.baseOriginalPriceObservable = undefined;
      this.originalPriceObservable = undefined;
      this.payoutPriceObservable = undefined;
    }
    this.editForm = form;
    if (!this.basePayoutPriceObservable) {
      if (this.editForm && this.editForm.controls && this.editForm.controls['base_price']) {
        this.basePayoutPriceObservable = this.convertBasePriceSubscriber('base_price', 'price');
      }
    }
    if (!this.baseOriginalPriceObservable) {
      if (this.editForm && this.editForm.controls && this.editForm.controls['base_original_price']) {
        this.baseOriginalPriceObservable = this.convertBasePriceSubscriber('base_original_price', 'original_price');
      }
    }
    if (!this.originalPriceObservable) {
      if (this.editForm && this.editForm.controls && this.editForm.controls['price']) {
        this.originalPriceObservable = this.convertPriceSubscriber('price', 'base_price');
      }
    }
    if (!this.payoutPriceObservable) {
      if (this.editForm && this.editForm.controls && this.editForm.controls['original_price']) {
        this.payoutPriceObservable = this.convertPriceSubscriber('original_price', 'base_original_price');
      }
    }
    if (this.editForm && this.editForm.controls && this.editForm.controls['instant_reward_price']) {
      this.editForm
        .get('instant_reward_price')
        .valueChanges.pipe(debounceTime(400))
        .subscribe((newVal) => {
          if (Number(newVal) > Number(this.editForm.controls['price'].value)) {
            AppInjector.get(NotificationService).show('warning', 'Instant reward can not greater than payout to original seller', 2000);
            this.editForm.controls['instant_reward_price'].setErrors({ required: true });
          }
          if (newVal === '0') {
            AppInjector.get(NotificationService).show('warning', 'Instant reward is invalid', 2000);
            this.editForm.controls['instant_reward_price'].setErrors({ required: true });
          }
        });
    }
  };

  convertBasePriceSubscriber = (base_field_name, field_name) => {
    return this.editForm
      .get(base_field_name)
      .valueChanges.pipe(debounceTime(400))
      .subscribe((newVal) => {
        if (base_field_name === 'base_original_price') {
          this.store.dispatch({
            type: PRODUCT_DATA_MODIFIED,
            data: {
              ...this.store.getState().Admin.Product.edit.item,
              ...this.getMinMaxRange(this.editForm.value.price),
              base_original_price: newVal
            }
          });
        }
        if (base_field_name === 'base_price') {
          this.store.dispatch({
            type: PRODUCT_DATA_MODIFIED,
            data: {
              ...this.store.getState().Admin.Product.edit.item,
              ...this.getMinMaxRange(this.editForm.value.price)
            }
          });
        }
        const api = AppInjector.get(ApiService).currency;
        const curr2 = this.editForm.get('currency_id').value.code;
        const mainSub = api
          .getConvertedAmount('EUR', curr2, newVal)
          .pipe(take(1))
          .subscribe((res) => {
            if (res && res.amount) {
              this.editForm.get(field_name).setValue(res.amount, { emitEvent: false });
              if (field_name === 'price' && this.editForm.get('instant_reward_price')) {
                this.editForm.get('instant_reward_price').setValue(Math.floor(res.amount * 0.8), { emitEvent: false });
              }
              if (field_name === 'base_original_price') {
                this.store.dispatch({
                  type: PRODUCT_DATA_MODIFIED,
                  data: {
                    ...this.store.getState().Admin.Product.edit.item,
                    ...this.getMinMaxRange(this.editForm.value.price),
                    base_original_price: res.amount
                  }
                });
              }
              if (base_field_name === 'base_price') {
                this.store.dispatch({
                  type: PRODUCT_DATA_MODIFIED,
                  data: {
                    ...this.store.getState().Admin.Product.edit.item,
                    ...this.getMinMaxRange(this.editForm.value.price)
                  }
                });
              }
            }
          });
      });
  };

  convertPriceSubscriber = (base_field_name, field_name) => {
    return this.editForm
      .get(base_field_name)
      .valueChanges.pipe(debounceTime(400))
      .subscribe((newVal) => {
        if (base_field_name === 'base_original_price') {
          this.store.dispatch({
            type: PRODUCT_DATA_MODIFIED,
            data: {
              ...this.store.getState().Admin.Product.edit.item,
              ...this.getMinMaxRange(this.editForm.value.price),
              base_original_price: newVal
            }
          });
        }
        if (base_field_name === 'price') {
          this.store.dispatch({
            type: PRODUCT_DATA_MODIFIED,
            data: {
              ...this.store.getState().Admin.Product.edit.item,
              ...this.getMinMaxRange(this.editForm.value.price)
            }
          });
        }
        const api = AppInjector.get(ApiService).currency;
        const curr2 = this.editForm.get('currency_id').value.code;
        const mainSub = api
          .getConvertedReverseAmount(curr2, 'EUR', newVal)
          .pipe(take(1))
          .subscribe((res) => {
            if (res && res.amount) {
              this.editForm.get(field_name).setValue(res.amount, { emitEvent: false });
              if (base_field_name === 'price' && this.editForm.get('instant_reward_price')) {
                this.editForm.get('instant_reward_price').setValue(Math.floor(newVal * 0.8), { emitEvent: false });
              }
              if (field_name === 'base_original_price') {
                this.store.dispatch({
                  type: PRODUCT_DATA_MODIFIED,
                  data: {
                    ...this.store.getState().Admin.Product.edit.item,
                    ...this.getMinMaxRange(this.editForm.value.price),
                    base_original_price: res.amount
                  }
                });
              }
              if (base_field_name === 'price') {
                this.store.dispatch({
                  type: PRODUCT_DATA_MODIFIED,
                  data: {
                    ...this.store.getState().Admin.Product.edit.item,
                    ...this.getMinMaxRange(this.editForm.value.price)
                  }
                });
              }
            }
          });
      });
  };

  onBeforeSubmit = (form) => {
    form.get('price').setValidators([Validators.required]);
    form.get('price').updateValueAndValidity();
    form.get('original_price').setValidators([Validators.required]);
    form.get('original_price').updateValueAndValidity();
    form.get('description').setValidators([Validators.required, Validators.minLength(50)]);
    form.get('description').updateValueAndValidity();
  };

  onBeforeCustomSubmit = (form) => {
    form.get('price').clearValidators();
    form.get('price').updateValueAndValidity();
    form.get('original_price').clearValidators();
    form.get('original_price').updateValueAndValidity();
    form.get('description').clearValidators();
    form.get('description').updateValueAndValidity();
  };

  /*onOriginalPriceUpdated = (value) => {
    this.store.dispatch({
      type: PRODUCT_DATA_MODIFIED,
      data: {
        ...this.store.getState().Admin.Product.edit.item,
        original_price: value
      }
    });
  };*/

  @HostListener('click', ['$event'])
  onClick(event) {
    if (event.target.id === 'my-button-send-quote') {
      this.sendQuote();
    }
    if (event.target.id === 'my-button-reject-buyback') {
      this.rejectBuyback();
    }
    if (event.target.id === 'my-button-send-final-quote') {
      this.sendFinalQuote();
    }
    if (event.target.id === 'my-button-reject-final-buyback') {
      this.rejectBuyback('true');
    }
    if (event.target.id === 'rf-price-conversion') {
      this.convertBasePrice('base_price', 'price');
      this.convertBasePrice('base_original_price', 'original_price');
    }
  }

  convertBasePrice(base_field_name, field_name) {
    const toCurrency = this.editForm.get('currency_id').value.code;
    AppInjector.get(ApiService)
      .currency.getConvertedAmount('EUR', toCurrency, this.editForm.get(base_field_name).value)
      .pipe(take(1))
      .subscribe((res) => {
        if (res && res.amount) {
          this.editForm.get(field_name).setValue(res.amount, { emitEvent: false });
          if (field_name === 'price' && this.editForm.get('instant_reward_price')) {
            this.editForm.get('instant_reward_price').setValue(Math.floor(res.amount * 0.8), { emitEvent: false });
          }
          if (field_name === 'base_original_price') {
            this.store.dispatch({
              type: PRODUCT_DATA_MODIFIED,
              data: {
                ...this.store.getState().Admin.Product.edit.item,
                ...this.getMinMaxRange(this.editForm.value.price),
                base_original_price: res.amount
              }
            });
          }
          if (base_field_name === 'base_price') {
            this.store.dispatch({
              type: PRODUCT_DATA_MODIFIED,
              data: {
                ...this.store.getState().Admin.Product.edit.item,
                ...this.getMinMaxRange(this.editForm.value.price)
              }
            });
          }
        }
      });
  }

  ngOnInit() {
    this.queryParams.highlight && this.queryParams.highlight === 'price' ? (this.showLocation = true) : (this.showLocation = false);
    if (this.queryParams.referer && this.queryParams.referer.includes('admin/collection/detail')) {
      this.from_collection = true;
      const i = setInterval(() => {
        if ($('.Products').length > 0 && $('.Collection').length > 0) {
          $('.Products').removeClass('active');
          $('.Collection').addClass('menu-text-color--active active');
          clearInterval(i);
        }
      });
    } else {
      this.from_collection = false;
    }
    this.onPriceSubmitRejectedPartial = this._onPriceSubmitPartial.bind(this, true);
    this.onPriceSubmitAcceptedPartial = this._onPriceSubmitPartial.bind(this, false);
    const block = new Block({
      classes: ['col-12', 'rf-form-group'],
      group_classes: ['col-12'],
      group: 1,
      content: `<div class="table-overflow ${this.queryParams.highlight === 'image' ? 'collection-product-table' : ''}">
      <div class="group-product">
        <div class="row">
          <div class="col-md-12 group-content">
            <div class="row">
              <div class="col-md-12">
                <div class="title-group">Product Identity</div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="title">
                  Reflaunt SKU: &nbsp;&nbsp;<span class="sku"></span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="title">
                  Group Sku: &nbsp;&nbsp;<span class="group_sku"></span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="title">Name: &nbsp;&nbsp;<span class="name"></span></div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="title">
                  Product id: &nbsp;&nbsp;<span class="product_id"></span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="title">
                  Collection Id: &nbsp;&nbsp;<span class="collection_id"></span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="title">
                  Warehouse item id: &nbsp;&nbsp;<span class="location"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="group-content row">
          <div class="col-md-12">
            <div class="title-group">Pricing</div>
          </div>
          <div class="col-md-6">
            <div class="title">
              Payout to original seller: &nbsp;&nbsp;<span class="price"></span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="title">
              Original price: &nbsp;&nbsp;<span class="original_price"></span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="title">
              Partner selling price: &nbsp;&nbsp;<span
                class="partner_selling_price"
              ></span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="title">
              Initial Price: &nbsp;&nbsp;<span class="initial_price"></span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="title">
              Payout to supplier currency: &nbsp;&nbsp;<span class="currency"></span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="title">
              Payout to supplier: &nbsp;&nbsp;<span class="payout_to_supplier"></span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="title">
              Smart Pricing : &nbsp;&nbsp;<span class="smart_pricing"></span>
            </div>
          </div>
        </div>
        <div class="group-content row">
          <div class="col-md-12">
            <div class="title-group">Attributes</div>
          </div>
          <div class="col-md-6">
            <div class="title">
              Category: &nbsp;&nbsp;<span class="category"></span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="title">
              Designer Name: &nbsp;&nbsp;<span class="designer"></span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="title">Color: &nbsp;&nbsp;<span class="color"></span></div>
          </div>
          <div class="col-md-6">
            <div class="title">Size: &nbsp;&nbsp;<span class="size"></span></div>
          </div>
          <div class="col-md-6">
            <div class="title">Gender: &nbsp;&nbsp;<span class="gender"></span></div>
          </div>
          <div class="col-md-6">
            <div class="title">
              Age group: &nbsp;&nbsp;<span class="age_group"></span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="title">
              Material: &nbsp;&nbsp;<span class="material"></span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-6">
                <div class="title">
                  Heel height: &nbsp;&nbsp;<span class="heel_height"></span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="title">
                  Platform height: &nbsp;&nbsp;<span class="platform_height"></span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-4 d-flex">
                <div class="title pr-2">
                  Width: &nbsp;&nbsp;<span class="width"></span>
                </div>
              </div>
              <div class="col-md-4 d-flex">
                <div class="title pr-2">
                  Length: &nbsp;&nbsp;<span class="length"></span>
                </div>
              </div>
              <div class="col-md-4 d-flex">
                <div class="title pr-2">
                  Height: &nbsp;&nbsp;<span class="height"></span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 d-flex">
                <div class="title pr-2">
                  Handle Drop: &nbsp;&nbsp;<span class="handle_drop"></span>
                </div>
              </div>
              <div class="col-md-6 d-flex">
                <div class="title pr-2">
                  Strap Length: &nbsp;&nbsp;<span class="strap_length"></span>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6 d-flex">
                <div class="title pr-2">
                  Model: &nbsp;&nbsp;<span class="model"></span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="title">
              Heel style: &nbsp;&nbsp;<span class="heel_style"></span>
            </div>
            <div class="row">
                <div class="col-md-4 d-flex">
                <div class="title pr-2">
                  Waist: &nbsp;&nbsp;<span class="waist"></span>
                </div>
              </div>
              <div class="col-md-4 d-flex">
                <div class="title pr-2">
                  Chest: &nbsp;&nbsp;<span class="chest"></span>
                </div>
              </div>
              <div class="col-md-4 d-flex">
                <div class="title pr-2">
                  Arm length: &nbsp;&nbsp;<span class="arm_length"></span>
                </div>
              </div>
          </div>
            </div>
        </div>
        <div class="group-content row">
          <div class="col-md-6">
            <div class="row">
              <div class="title-group col-md-12">Quantity</div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="title">
                  Remaining: &nbsp;&nbsp;<span class="quantity"></span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-12">
                <div class="title-group">Condition</div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="title">
                  Condition: &nbsp;&nbsp;<span class="condition"></span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="title">
                  Sign of wear: &nbsp;&nbsp;<span class="sign_of_wear"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="group-content row">
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-12">
                <div class="title-group">Customer</div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="title">
                  Customer email: &nbsp;&nbsp;<span class="customer_email"></span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 group-content">
            <div class="row">
              <div class="col-md-12">
                <div class="title-group">Supplier</div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="title">
                  Supplier SKU: &nbsp;&nbsp;<span class="supplier_sku"></span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="title">
                  Supplier email: &nbsp;&nbsp;<span class="retailer_email"></span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="title">
                  Website: &nbsp;&nbsp;<span class="website"></span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="title">
                  Supplier name: &nbsp;&nbsp;<span class="supplier_name"></span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="title">
                  Retailer UPC: &nbsp;&nbsp;<span class="upc"></span>
                </div>
              </div>
              <div class="col-md-6">
                <div class="title">
                  Service Type: &nbsp;&nbsp;<span class="service_type_id"></span>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-12">
                <div class="title-group">Tags</div>
              </div>
            </div>
            <div class="row">
              <div class="col-md-6">
                <div class="title">Tags: &nbsp;&nbsp;<span class="tags"></span></div>
              </div>
            </div>
          </div>
        </div>
        <div class="group-content row">
          <div class="col-md-12">
            <div class="title-group">Note</div>
          </div>
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-12 title">Warehouse reject notes:</div>
            </div>
            <div class="row">
              <div class="col-md-12 note_condition_description pb-3"></div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-12 title">Description of condition:</div>
            </div>
            <div class="row">
              <div class="col-md-12 condition_description unset-margin-left"></div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-12 title">Private note:</div>
            </div>
            <div class="row">
              <div class="col-md-12 private_note unset-margin-left"></div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-12 title">Description</div>
            </div>
            <div class="row">
              <div class="col-md-12 description unset-margin-left"></div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-12 title">Warehouse reject reason:</div>
            </div>
            <div class="row">
              <div class="col-md-12 warehouse_reject_reason unset-margin-left"></div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-12 title">Curation reject reason:</div>
            </div>
            <div class="row">
              <div class="col-md-12 curation_reject_reason unset-margin-left"></div>
            </div>
          </div>
          <div class="col-md-6">
            <div class="row">
              <div class="col-md-12 title">Curation reject notes:</div>
            </div>
            <div class="row">
              <div class="col-md-12 curation_reject_note unset-margin-left"></div>
            </div>
          </div>
        </div>
        <div class="group-content row">
          <div class="col-md-12">
            <div class="title-group">Other</div>
          </div>

          <div class="col-md-6">
            <div class="title">
              Authenticity card number: &nbsp;&nbsp;<span
                class="authentication_id"
              ></span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="title">
              Packaging: &nbsp;&nbsp;<span class="packaging"></span>
            </div>
          </div>
        </div>
        <div class="group-content row">
          <div class="col-md-12">
            <div class="title-group">Retailer attributes</div>
          </div>

          <div class="col-md-6">
            <div class="title">
              Retailer category: &nbsp;&nbsp;<span
                class="retailer_category"
              ></span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="title">
              Retailer size: &nbsp;&nbsp;<span class="retailer_size"></span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="title">
              Retailer condition: &nbsp;&nbsp;<span
                class="retailer_condition"
              ></span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="title">
              Retailer material: &nbsp;&nbsp;<span class="retaler_material"></span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="title">
              Retailer color: &nbsp;&nbsp;<span
                class="retailer_color"
              ></span>
            </div>
          </div>
          <div class="col-md-6">
            <div class="title">
              Retailer designer: &nbsp;&nbsp;<span class="retailer_designer"></span>
            </div>
          </div>
        </div>
      </div>

      </div>
      `
    });
    const pricingBlock = new Block({
      classes: ['col-12', 'rf-form-group'],
      group_classes: ['col-12'],
      order: 1,
      content: `<table class="table m-0">
          <tbody>
            <tr>
              <td>
                <div class="row"><span class="left-side col-3">Product Name</span><span class="left-side col-1">:</span><span class="col-8 pricing-product-name"></span></div>
              </td>
            </tr>
            <tr>
              <td>
                <div class="row"><span class="left-side col-3">Description</span><span class="left-side col-1">:</span><span class="col-8 pricing-product-description"></span></div>
              </td>
            </tr>
            </tbody></table>`
    });
    this.inputs = [
      new CustomUploadImages({
        key: 'images',
        label: 'Images',
        classes: ['col-12', 'rf-form-group', 'input--upload-file'],
        group_classes: ['col-12'],
        group: 0,
        accept: 'png|jpg|jpeg|tiff',
        allowMaxSize: 10,
        mutiple: true,
        apiUpload: '/api/v1/upload',
        apiRotate: '/api/v1/upload/rotate',
        apiRetouch: '/api/v1/upload/retouch',
        retouchCallback: () =>
          this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate(['/', 'admin', 'product', 'edit', this.getProductId()])),
        isRetouchEnabled: true,
        resultTransformer: (result) => result.data[0].full_path,
        paramName: 'files',
        hasOutlines: true,
        apiUpdate: (id) => `/api/v1/admin/images/${id}`,
        isOptionsEnabled: true
      }),
      block,
      new Block({
        classes: ['col-12', 'rf-form-group', 'custom-css'],
        group_classes: ['col-md-12', 'group'],
        group: 11,
        content: `<div class="group-title-text">Product Identity:</div>`
      }),
      new TextBox({
        key: 'sku',
        label: 'Reflaunt SKU',
        classes: ['col-6', 'rf-form-group', 'custom-css'],
        group_classes: ['col-md-6', 'group'],
        group: 11
        // validators: [
        //   {
        //     validator: Validators.required,
        //     message: 'This field is required'
        //   }
        // ]
      }),
      new TextBox({
        key: 'name',
        label: 'Name *',
        classes: ['col-6', 'rf-form-group', 'custom-css'],
        group_classes: ['col-md-6', 'group'],
        group: 11,
        validators: [
          {
            validator: Validators.required,
            message: 'This field is required'
          }
        ]
      }),
      new TextBox({
        key: 'group_sku',
        label: 'Group SKU',
        classes: ['col-6', 'rf-form-group', 'custom-css'],
        group_classes: ['col-md-6', 'group'],
        group: 11
      }),
      new TextBox({
        key: 'collection_id',
        label: 'Collection Id',
        classes: ['col-6', 'rf-form-group', 'custom-css'],
        group_classes: ['col-md-6', 'group'],
        group: 11
      }),
      new TextBox({
        key: 'location',
        label: 'Warehouse item id',
        classes: ['col-6', 'rf-form-group', 'custom-css'],
        group_classes: ['col-md-6', 'group'],
        group: 11
      }),
      // end group Product Identity
      new Block({
        classes: ['col-12', 'rf-form-group', 'custom-css'],
        group_classes: ['col-md-6', 'group'],
        group: 23,
        content: `<div class="group-title-text">Customer:</div>`
      }),
      new TextBox({
        key: 'customer_email',
        label: 'Customer Email *',
        classes: ['col-12', 'rf-form-group', 'custom-css'],
        group_classes: ['col-md-6', 'group'],
        group: 23,
        validators: [
          {
            validator: Validators.required,
            message: 'This field is required'
          }
        ]
      }),
      // end group - Customer
      new Block({
        classes: ['col-12', 'rf-form-group', 'custom-css'],
        group_classes: ['col-md-6', 'group'],
        group: 22,
        content: `<div class="group-title-text">Supplier:</div>`
      }),
      new TextBox({
        key: 'supplier_sku',
        label: 'Supplier SKU',
        classes: ['col-6', 'rf-form-group', 'custom-css'],
        group_classes: ['col-md-6', 'group'],
        group: 22
        // validators: [
        //   {
        //     validator: Validators.required,
        //     message: 'This field is required'
        //   }
        // ]
      }),
      new TextBox({
        key: 'retailer_email',
        label: 'Supplier email *',
        classes: ['col-6', 'rf-form-group', 'custom-css'],
        group_classes: ['col-md-6', 'group'],
        group: 22,
        validators: [
          {
            validator: Validators.required,
            message: 'This field is required'
          },
          {
            validator: Validators.email,
            message: 'This field must be an email'
          }
        ]
      }),
      new TextBox({
        key: 'website',
        label: 'Website',
        classes: ['col-6', 'rf-form-group', 'custom-css'],
        group_classes: ['col-md-6', 'group'],
        group: 22
      }),
      new TextBox({
        key: 'upc',
        label: 'Retailer UPC',
        classes: ['col-6', 'rf-form-group', 'custom-css'],
        group_classes: ['col-md-6', 'group'],
        group: 22
      }),
      new Dropdown({
        key: 'service_type_id',
        label: 'Service Type',
        classes: ['col-12', 'rf-form-group', 'custom-css', 'input--select'],
        group_classes: ['col-md-12', 'group'],
        group: 22
      }),
      // end group - Supplier

      new Block({
        classes: ['col-12', 'rf-form-group', 'custom-css'],
        group_classes: ['col-12', 'group'],
        group: 33,
        content: `<div class="group-title-text">Pricing:</div>`
      }),
      new Dropdown({
        key: 'currency_id',
        label: 'Payout to supplier currency',
        classes: ['col-6', 'rf-form-group', 'custom-css', 'input--select'],
        group_classes: ['col-12', 'group'],
        group: 33
      }),
      new TextBox({
        key: 'price',
        label: 'Payout to original seller *',
        classes: ['col-6', 'rf-form-group', 'custom-css'],
        group_classes: ['col-12', 'group'],
        type: 'number',
        group: 33,
        validators: [
          {
            validator: Validators.required,
            message: 'This field is required'
          },
          {
            validator: Validators.pattern(/^[.\d]+$/),
            message: 'This field must be a number'
          }
        ]
      }),
      new TextBox({
        key: 'price',
        label: 'Payout to supplier',
        type: 'number',
        validators: [
          {
            validator: Validators.pattern(/^[.\d]+$/),
            message: 'This field must be a number'
          }
        ],
        classes: ['col-6', 'rf-form-group', 'custom-css'],
        group_classes: ['col-12', 'group'],
        group: 33
      }),
      new TextBox({
        key: 'original_price',
        label: 'Original Price *',
        classes: ['col-6', 'rf-form-group', 'custom-css'],
        group_classes: ['col-12', 'group'],
        type: 'number',
        group: 33,
        validators: [
          {
            validator: Validators.required,
            message: 'This field is required'
          },
          {
            validator: Validators.pattern(/^[.\d]+$/),
            message: 'This field must be a number'
          }
        ]
      }),
      new TextBox({
        key: 'partner_selling_price',
        label: 'Partner selling price',
        classes: ['col-12', 'rf-form-group', 'custom-css'],
        group_classes: ['col-12', 'group'],
        group: 33,
        type: 'number',
        validators: [
          {
            validator: Validators.pattern(/^[.\d]+$/),
            message: 'This field must be a number'
          }
        ]
      }),
      // end group -Pricing
      new Block({
        classes: ['col-12', 'rf-form-group', 'custom-css'],
        group_classes: ['col-md-6', 'group'],
        group: 44,
        content: `<div class="group-title-text">Quantity:</div>`
      }),
      new TextBox({
        key: 'quantity',
        label: 'REMAINING QUANTITY',
        classes: ['col-12', 'rf-form-group', 'custom-css'],
        group_classes: ['col-md-6', 'group'],
        group: 44,
        // type: 'number',
        validators: [
          {
            validator: Validators.pattern('^[0-9]*$'),
            message: 'This field must be a number'
          }
        ]
      }),
      // end group - Quantity
      new Block({
        classes: ['col-12', 'rf-form-group', 'custom-css'],
        group_classes: ['col-md-6', 'group'],
        group: 45,
        content: `<div class="group-title-text">Condition:</div>`
      }),
      new Dropdown({
        key: 'condition_id',
        label: 'Condition',
        classes: ['col-6', 'rf-form-group', 'custom-css', 'input--select'],
        group_classes: ['col-md-6', 'group'],
        group: 45,
        validators: [
          {
            validator: Validators.required,
            message: 'This field is required'
          }
        ]
      }),
      new Dropdown({
        key: 'sign_of_wear',
        label: 'Sign of wear',
        classes: ['col-6', 'rf-form-group', 'custom-css', 'input--select'],
        group_classes: ['col-md-6', 'group'],
        group: 45
      }),
      // end group - Condition
      new Block({
        classes: ['col-12', 'rf-form-group', 'custom-css'],
        group_classes: ['col-12', 'group'],
        group: 55,
        content: `<div class="group-title-text">Attributes:</div>`
      }),
      new Dropdown({
        key: 'category_id',
        label: 'Category *',
        classes: ['col-6', 'rf-form-group', 'custom-css', 'input--select'],
        group_classes: ['col-12', 'group'],
        group: 55,
        validators: [
          {
            validator: Validators.required,
            message: 'This field is required'
          }
        ]
      }),
      new TypeAheadWithoutApi({
        key: 'designer_id',
        label: 'Designer name',
        classes: ['col-6', 'rf-form-group', 'custom-css', 'input--select'],
        group_classes: ['col-12', 'group'],
        searchRaw: true,
        group: 55
      }),
      new TypeAheadWithoutApi({
        key: 'color_id',
        label: 'Color',
        classes: ['col-6', 'rf-form-group', 'custom-css', 'input--select'],
        group_classes: ['col-12', 'group'],
        group: 55
      }),
      new TypeAhead({
        key: 'size_id',
        label: 'Size',
        classes: ['col-6', 'rf-form-group', 'custom-css', 'input--select'],
        group_classes: ['col-12', 'group'],
        group: 55,
        apiUpload: `/api/v1/sizes?page=1&constraints=${encodeURIComponent(JSON.stringify({ "is_old_size":false }))}&search=`,
        resultTransformer: (result) => result.data.map((e) => {
          e.name = this.convertNameSize(e)
          return e
        }),
        fieldName: (result) => result.name,
        validators: [
          {
            validator: Validators.required,
            message: 'This field is required'
          }
        ],
        multiple: false,
        keyName: ['name'],
        searchBy: 'name'
      }),
      new Dropdown({
        key: 'gender',
        label: 'Gender',
        classes: ['col-6', 'rf-form-group', 'custom-css', 'input--select'],
        group_classes: ['col-12', 'group'],
        group: 55
      }),
      new Dropdown({
        key: 'age_group',
        label: 'Age Group',
        classes: ['col-6', 'rf-form-group', 'custom-css', 'input--select'],
        group_classes: ['col-12', 'group'],
        group: 55
      }),
      new TypeAheadWithoutApi({
        key: 'material_id',
        label: 'Material',
        classes: ['col-6', 'rf-form-group', 'custom-css', 'input--select'],
        group_classes: ['col-12', 'group'],
        group: 55
      }),
      new TextBox({
        key: 'material_string',
        label: 'Material String',
        classes: ['col-6', 'rf-form-group', 'custom-css'],
        group_classes: ['col-12', 'group'],
        group: 55
      }),
      new Dropdown({
        key: 'clothing_size',
        label: 'Clothing Size',
        classes: ['col-6', 'rf-form-group', 'custom-css', 'input--select'],
        group_classes: ['col-12', 'group'],
        group: 55
      }),

      /* new TextBoxMask({
        key: 'dimensions',
        label: 'Dimensions',
        classes: ['col-6', 'rf-form-group', 'custom-css'],
        group_classes: ['col-12', 'group'],
        group: 1,
        mask: [/\d/, /\d/, /\d/, 'x', /\d/, /\d/, /\d/, 'x', /\d/, /\d/, /\d/],
        guide: true,
        valueWithCharacter: true
      })*/
      new TextBox({
        key: 'width',
        label: 'Width (mm)',
        classes: ['col-2', 'rf-form-group', 'custom-css'],
        group_classes: ['col-12', 'group'],
        group: 55
      }),
      new TextBox({
        key: 'length',
        label: 'Length (mm)',
        classes: ['col-2', 'rf-form-group', 'custom-css'],
        group_classes: ['col-12', 'group'],
        group: 55
      }),
      new TextBox({
        key: 'height',
        label: 'Height (mm)',
        classes: ['col-2', 'rf-form-group', 'custom-css'],
        group_classes: ['col-12', 'group'],
        group: 55
      }),
      new TextBox({
        key: 'handle_drop',
        label: 'Handle Drop',
        classes: ['col-6', 'rf-form-group', 'custom-css'],
        group_classes: ['col-12', 'group'],
        group: 55
      }),
      new TextBox({
        key: 'strap_length',
        label: 'Strap Length',
        classes: ['col-6', 'rf-form-group', 'custom-css'],
        group_classes: ['col-12', 'group'],
        group: 55
      }),
      new TextBox({
        key: 'heel_height',
        label: 'Heel Height',
        classes: ['col-6', 'rf-form-group', 'custom-css'],
        group_classes: ['col-12', 'group'],
        group: 55
      }),
      new TypeAheadWithoutApi({
        key: 'model_id',
        label: 'Model',
        classes: ['col-6', 'rf-form-group', 'custom-css', 'input--select'],
        group_classes: ['col-12', 'group'],
        group: 55
      }),
      new TextBox({
        key: 'platform_height',
        label: 'Platform Height',
        classes: ['col-12', 'rf-form-group', 'custom-css'],
        group_classes: ['col-12', 'group'],
        group: 55
      }),
      new TextArea({
        key: 'description',
        label: 'Description',
        classes: ['col-12', 'rf-form-group', 'custom-css'],
        group_classes: ['col-12', 'group'],
        group: 55
      }),
      // end group - Attributes
      new Block({
        classes: ['col-12', 'rf-form-group', 'custom-css'],
        group_classes: ['col-md-12', 'group'],
        group: 77,
        content: `<div class="group-title-text">Tags:</div>`
      }),
      new Select2({
        key: 'tags',
        label: 'Tags',
        classes: ['col-6', 'rf-form-group', 'custom-css', 'input--select2'],
        group_classes: ['col-md-6', 'group'],
        group: 77
      }),
      new Dropdown({
        key: 'retail_tag',
        label: 'Retail tag attached',
        classes: ['col-6', 'rf-form-group', 'custom-css', 'input--select'],
        group_classes: ['col-md-6', 'group'],
        group: 77
      }),
      // end group - Tags
      new Block({
        classes: ['col-12', 'rf-form-group', 'custom-css'],
        group_classes: ['col-12', 'group'],
        group: 88,
        content: `<div class="group-title-text">Note:</div>`
      }),
      new TextArea({
        key: 'condition_description',
        label: 'Description of condition',
        classes: ['col-12', 'rf-form-group', 'custom-css'],
        group_classes: ['col-12', 'group'],
        group: 88
      }),
      new TextArea({
        key: 'private_note',
        label: 'Private note',
        classes: ['col-12', 'rf-form-group', 'custom-css'],
        group_classes: ['col-12', 'group'],
        group: 88
      })
      // end group - Note
      // new Block({
      //   classes: ['col-12', 'rf-form-group', 'custom-css'],
      //   group_classes: ['col-12', 'group'],
      //   group: 100,
      //   content: `<div class="group-title-text">Other:</div>`
      // }),
      // end group - Other
    ];

    if (this.queryParams.referer) {
      if (this.queryParams.referer.indexOf('collection') > -1) {
        if (this.queryParams.highlight === 'image') {
          this.inputs = [
            block,
            // new Block({
            //   key: 'private_images_title',
            //   classes: ['col-12', 'rf-form-group'],
            //   group_classes: ['col-12'],
            //   order: 1,
            //   content: `<div class="">Private images (not retouched)</div>`
            // }),
            // new CustomUploadImages({
            //   key: 'private_images',
            //   label: 'Images',
            //   classes: ['col-12', 'rf-form-group', 'input--upload-file'],
            //   group_classes: ['col-12'],
            //   order: 1,
            //   accept: 'png|jpg|jpeg',
            //   allowMaxSize: 2,
            //   apiUpload: '/api/v1/upload',
            //   apiRotate: '/api/v1/upload/rotate',
            //   apiRetouch: '/api/v1/upload/retouch',
            //   retouchCallback: () =>
            //     this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate(['/', 'admin', 'product', 'edit', this.getProductId()])),
            //   isRetouchEnabled: true,
            //   resultTransformer: (result) => result.data[0].full_path,
            //   paramName: 'files',
            //   hasOutlines: true
            // }),
            new Block({
              classes: ['col-12', 'rf-form-group'],
              group_classes: ['col-12'],
              order: 1,
              content: `<div class="">Public images</div>`
            }),
            new CustomUploadImages({
              key: 'public_images',
              label: 'Images',
              classes: ['col-12', 'rf-form-group', 'input--upload-file'],
              group_classes: ['col-12'],
              order: 1,
              accept: 'png|jpg|jpeg',
              allowMaxSize: 10,
              mutiple: true,
              apiUpload: '/api/v1/upload/s3Url',
              apiRotate: '/api/v1/upload/rotate',
              apiRetouch: '/api/v1/upload/retouch',
              retouchCallback: () =>
                this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate(['/', 'admin', 'product', 'edit', this.getProductId()])),
              isRetouchEnabled: true,
              resultTransformer: (result) => result.data[0].full_path,
              paramName: 'files',
              hasOutlines: true,
              isDirectUpload: true,
              validators: [
                {
                  validator: Validators.required,
                  message: 'This field is required'
                }
              ]
            })
          ];
        } else if (this.queryParams.highlight === 'price') {
          this.inputs = [
            block,
            new ImageGallery({
              key: 'images_carousel',
              classes: ['col-12', 'rf-form-group', 'custom-image-gallery', 'custom-image-gallery-hidden'],
              group_classes: ['col-12'],
              order: 1,
              configs: [
                {
                  width: '700px',
                  height: '800px',
                  thumbnailsColumns: 4
                }
              ]
            }),
            new Block({
              key: 'private-images__title',
              classes: ['col-12', 'rf-form-group', 'private-images'],
              group_classes: ['col-12'],
              order: 1,
              content: `<div class="private-images__title">Private images</div>`
            }),
            new ImageZoom({
              key: 'image_private_zoom',
              classes: ['col-12', 'rf-form-group', 'custom-image-zoom'],
              group_classes: ['col-12'],
              order: 1,
              configs: {
                lensWidth: '200',
                lensHeight: '200'
              }
            }),
            new Block({
              classes: ['col-12', 'rf-form-group', 'public-images'],
              group_classes: ['col-12'],
              order: 1,
              content: `<div class="public-images__title">Public images</div>`
            }),
            new ImageZoom({
              key: 'image_public_zoom',
              classes: ['col-12', 'rf-form-group', 'custom-image-zoom'],
              group_classes: ['col-12'],
              order: 1,
              configs: {
                lensWidth: '200',
                lensHeight: '200'
              }
            }),
            new TextBox({
              key: 'name',
              label: 'Name *',
              classes: ['col-12', 'rf-form-group'],
              group_classes: ['col-12'],
              order: 1,
              validators: [
                {
                  validator: Validators.required,
                  message: 'This field is required'
                }
              ]
            }),
            new Block({
              classes: ['col-6', 'rf-form-group'],
              group_classes: ['col-12'],
              order: 1,
              content: `<div>Base currency: <br /> <br /> EUR</div>`
            }),
            new Dropdown({
              key: 'currency_id',
              label: 'Payout to original seller currency',
              classes: ['col-6', 'rf-form-group', 'input--select'],
              group_classes: ['col-12'],
              order: 1,
              validators: [
                {
                  validator: Validators.required,
                  message: 'This field is required'
                }
              ]
            }),
            new Radio({
              key: 'color_changed_at',
              options: [
                { value: 'yes', label: 'Yes' },
                { value: 'no', label: 'No' }
              ],
              disabled: true,
              label: 'Was Colour Modified',
              classes: ['col-12', 'rf-form-group', 'condition-group', 'custom-radiofill'],
              group_classes: ['col-12', 'condition']
            }),
            new Radio({
              key: 'is_eligible_for_instant_reward',
              options: [
                { value: 'yes', label: 'Yes' },
                { value: 'no', label: 'No' }
              ],
              onChange: this.onChangeInstantRewardOption,
              label: 'Eligible for instant reward',
              classes: ['col-6', 'rf-form-group', 'condition-group', 'custom-radiofill'],
              group_classes: ['col-6', 'condition']
            }),
            new TextBox({
              key: 'instant_reward_payout_range',
              label: 'Potential payout range',
              disabled: true,
              classes: ['col-6', 'rf-form-group', 'condition-group', 'custom-radiofill'],
              group_classes: ['col-6', 'condition']
            }),
            new TextBox({
              key: 'base_original_price',
              label: 'Base Original Price',
              classes: ['col-6', 'rf-form-group'],
              group_classes: ['col-12'],
              order: 1
            }),
            new TextBox({
              key: 'original_price',
              label: 'Original Price *',
              classes: ['col-6', 'rf-form-group'],
              group_classes: ['col-12'],
              order: 1,
              // onChange: this.onOriginalPriceUpdated,
              validators: [
                {
                  validator: Validators.required,
                  message: 'This field is required'
                }
              ]
            }),
            new TextBox({
              key: 'base_price',
              label: 'Base Payout To Original seller',
              classes: ['col-6', 'rf-form-group'],
              group_classes: ['col-12'],
              order: 1
            }),
            new TextBox({
              key: 'price',
              label: 'PAYOUT TO original seller *',
              classes: ['col-6', 'rf-form-group'],
              group_classes: ['col-12'],
              order: 1,
              validators: [
                {
                  validator: Validators.required,
                  message: 'This field is required'
                }
              ]
            }),
            new Block({
              classes: ['col-6', 'rf-form-group'],
              group_classes: ['col-6'],
              order: 1,
              content: `<div class="reflaunt-smart-pricer"></div>`
            }),
            new Block({
              classes: ['col-6', 'rf-form-group'],
              group_classes: ['col-6'],
              order: 1,
              content: `<div class="rf-price"></div>`
            }),
            new Block({
              classes: ['col-12', 'rf-form-group'],
              group_classes: ['col-12'],
              order: 1,
              content: `<div class="buyback"></div>`
            }),
            new TextArea({
              key: 'description',
              label: 'Description *',
              classes: ['col-12', 'rf-form-group'],
              group_classes: ['col-12'],
              order: 1,
              validators: [
                {
                  validator: Validators.required,
                  message: 'This field is required'
                },
                {
                  validator: Validators.minLength(50),
                  message: 'This field required minimum of 50 characters'
                }
              ]
            })
          ];
        }
        setTimeout(() => {
          this.editMode();
        });
        this.isDisplayMarketplace = false;
      }
    }
    this.store.dispatch({
      type: RENDER_EDIT_PRODUCT_FORM_REQUESTED,
      data: { id: this.getProductId(), inputs: this.inputs, highlight: this.queryParams.highlight, collectionId: this.collectionId },
      param: { includes: 'categories,seller,retailer,currency,shipments,ServiceType' }
    });
    fromEvent(document, 'ReflauntJsExposeDataToClient')
      .pipe(
        filter((evt: any) => evt.detail && evt.detail.type === 'predict_data' && evt.detail.data && evt.detail.data.recommended_price),
        filter(() => this.editForm),
        map((evt: any) => Math.ceil(evt.detail.data.recommended_price))
      )
      .subscribe((recommendedPrice) => {
        this.editForm.get('base_price').setValue(recommendedPrice);
      });
    this.store.dispatch({
      type: GET_PRODUCT_ITEM_BY_PRODUCT_ID_REQUESTED,
      data: {
        product_id: this.getProductId()
      }
    });
    this.store.dispatch({ type: FETCH_ALL_STATUS_REQUESTED });
    document.addEventListener('ReflauntJsExposeDataToClient', (e: any) => {
      switch (e.detail.type) {
        case 'SEND_COMMNET_SMART_PRICER_TYPE': {
          this.store.dispatch({ type: SAVE_COMMENT_FROM_SMART_PRICER, data: e.detail.data });
          break;
        }
      }
    });
  }

  onChangeInstantRewardOption(value) {
    const store = AppInjector.get(Store).getInstance();
    store.dispatch({ type: UPDATE_FORM_INPUT, value: value });
  }

  loadRouter() {
    this.router.routeReuseStrategy.shouldReuseRoute = () => false;
  }
  getProductId() {
    let productId = this.route.snapshot.paramMap.get('id');
    return productId;
  }

  onSubmit(form) {
    const store = AppInjector.get(Store).getInstance();
    const { privateImages } = store.getState().Admin.Product.edit;
    if (form.valid) {
      let data = {
        sku: form.value.sku,
        supplier_sku: form.value.supplier_sku,
        name: form.value.name,
        location: form.value.location,
        warehouse_sku: form.value.location,
        price: form.value.price || null,
        partner_selling_price: form.value.partner_selling_price ? form.value.partner_selling_price : 0,
        original_price: form.value.original_price,
        designer_id: form.value.designer_id.id,
        size_id: form.value.size_id.id,
        heel_height: form.value.heel_height,
        platform_height: !isEmpty(form.value.platform_height) || form.value.platform_height !== '' ? form.value.platform_height : null,
        /*dimensions: form.value.dimensions,*/
        width: form.value.width || null,
        length: form.value.length || null,
        height: form.value.height || null,
        handle_drop: form.value.handle_drop || null,
        strap_length: form.value.strap_length || null,
        material: form.value.material,
        color_id: form.value.color_id.id,
        upc: form.value.upc,
        customer_email: form.value.customer_email,
        category_id: form.value.category_id.id,
        condition_id: form.value.condition_id.id,
        website: form.value.website,
        images: form.value.images.concat(privateImages),
        description: form.value.description,
        private_note: form.value.private_note,
        condition_description: form.value.condition_description,
        retailer_email: form.value.retailer_email,
        quantity: form.value.quantity,
        currency_id: form.value.currency_id.id,
        service_type_id: form.value.service_type_id.id,
        group_sku: form.value.group_sku,
        retail_tag: form.value.retail_tag.value,
        sign_of_wear: form.value.sign_of_wear.value,
        gender: form.value.gender.value,
        age_group: form.value.age_group.value,
        tags: form.value.tags.map((tag) => parseInt(tag, 10)),
        image_validated_status: 'pending',
        material_id: form.value.material_id.id,
        model_id: form.value.model_id.id,
        custom_attributes: {
          clothing_size: form.value.clothing_size.value
        },
        change_all_product_item_price: form.value.change_all_product_item_price ? form.value.change_all_product_item_price.value : null
      };
      if (isNaN(data.tags[0]) || isUndefined(data.tags[0])) {
        delete data.tags;
      }
      store.dispatch({
        type: UPDATE_PRODUCT_REQUESTED,
        data: assign(data, { id: store.getState().Admin.Product.edit.item.id, editType: 'LINK_TO_PRODUCT_PAGE', queryParams: Cookies.get('queryParams') })
      });
    }
  }
  onImageSubmitPartial = (form) => {
    const store = AppInjector.get(Store).getInstance();
    if (form.valid) {
      let data: any = {};
      if (this.queryParams.highlight === 'image') {
        let privateImages = [];
        const images = store.getState().Admin.Product.edit.images || [];
        if (images.length > 0) {
          privateImages = images.filter((e) => e.is_private).map((img) => assign(img, { url: img.original_url }));
        }
        const publicImages = form.value.public_images;
        data = {
          images: privateImages.concat(publicImages),
          location: form.value.location,
          warehouse_sku: form.value.location,
          steps_create_collection: this.stepsCreateCollection.step_2,
          image_validated_status: 'pending'
        };
      }
      store.dispatch({
        type: UPDATE_PRODUCT_REQUESTED,
        data: assign(data, { id: store.getState().Admin.Product.edit.item.id, editType: 'LINK_TO_PRODUCT_PAGE', queryParams: Cookies.get('queryParams') }),
        referer: this.queryParams.referer,
        params: this.paramPricing,
        highlight: 'image'
      });
    }
  };

  _onPriceSubmitPartial = (is_rejected, form) => {
    if (form.valid) {
      let data_popup;
      if (is_rejected) {
        if (form.value.is_eligible_for_instant_reward === 'yes') {
          AppInjector.get(NotificationService).show('warning', 'Instant reward is not allowed for reject item', 2000);
          return;
        }
        Swal.fire({
          title: 'Do you want to reject this product?',
          showCancelButton: true,
          html: this.renderSelectCancelReason(),
          focusConfirm: false,
          preConfirm: () => {
            const reject_reason = (document.getElementById('swal-select-cancel-reason') as any).value;
            return {
              reject_reason
            };
          }
        }).then((result) => {
          let value;
          if (result.value) {
            value = true;
          } else if (result.dismiss) {
            value = false;
          }
          if (value) {
            data_popup = result;
            this._onPriceSubmitPartialFn(form, is_rejected, data_popup);
          }
        });
      } else {
        this._onPriceSubmitPartialFn(form, is_rejected);
      }
    }
  };

  _onPriceSubmitPartialFn = (form, is_rejected, data_popup?) => {
    let data: any = {};
    if (this.queryParams.highlight === 'price') {
      data = {
        price: form.value.price || 0,
        original_price: form.value.original_price,
        currency_id: form.value.currency_id.id,
        description: form.value.description,
        name: form.value.name,
        location: form.value.location,
        warehouse_sku: form.value.location,
        steps_create_collection: this.stepsCreateCollection.step_3,
        is_eligible_for_instant_reward: form.value.is_eligible_for_instant_reward === 'yes' ? true : false,
        instant_reward_price: form.value.is_eligible_for_instant_reward === 'yes' ? form.value.instant_reward_price : null
      };
      if (this.collection && this.collection.retailer.email === 'balenciaga@reflaunt.com') {
        data.partner_selling_price = getSellingPrice(data.price);
      }
      if (this.collection && this.collection.retailer.email === 'modes@reflaunt.com') {
        data.partner_selling_price = getSellingPrice(data.price);
      }
    }
    if (is_rejected && data_popup) {
      data.reject_reason = data_popup.value.reject_reason;
    }

    data = assign(data, {
      id: this.store.getState().Admin.Product.edit.item.id,
      editType: 'LINK_TO_PRODUCT_PAGE',
      queryParams: Cookies.get('queryParams'),
      is_rejected,
      update_image: false,
      from: 'collection_page'
    });
    this.updateProductAndSaveSmartPricer(data);
    return;
  };

  updateProductAndSaveSmartPricer = (data) => {
    const store = AppInjector.get(Store).getInstance();
    store.dispatch({
      type: UPDATE_PRODUCT_REQUESTED,
      data,
      referer: this.queryParams.referer,
      collectionId: this.collectionId,
      highlight: 'price'
    });
    if (store.getState().Admin.Product.edit.smart_pricer_comment && store.getState().Admin.Product.edit.smart_pricer_comment.Item) {
      store.dispatch({
        type: SAVE_SMART_PRICER_COMMENT_REQUEST,
        data: store.getState().Admin.Product.edit.smart_pricer_comment,
        collectionId: this.collectionId,
        highlight: 'price'
      });
    }
  };

  updateAndContinueEdit = (form) => {
    const store = AppInjector.get(Store).getInstance();
    const { privateImages } = store.getState().Admin.Product.edit;
    if (form.valid) {
      let data = {
        sku: form.value.sku,
        supplier_sku: form.value.supplier_sku,
        name: form.value.name,
        location: form.value.location,
        warehouse_sku: form.value.location,
        price: form.value.price || null,
        partner_selling_price: form.value.partner_selling_price || null,
        original_price: form.value.original_price,
        designer_id: form.value.designer_id.id,
        size_id: form.value.size_id.id,
        heel_height: form.value.heel_height,
        platform_height: !isEmpty(form.value.platform_height) || form.value.platform_height !== '' ? form.value.platform_height : null,
        // dimensions: form.value.dimensions,
        width: form.value.width || null,
        length: form.value.length || null,
        height: form.value.height || null,
        handle_drop: form.value.handle_drop || null,
        strap_length: form.value.strap_length || null,
        material: form.value.material,
        color_id: form.value.color_id.id,
        upc: form.value.upc,
        collection_id: form.value.collection_id || null,
        customer_email: form.value.customer_email,
        category_id: form.value.category_id.id,
        condition_id: form.value.condition_id.id,
        website: form.value.website,
        // images: map(form.value.images, i => i.urlZoom),
        images: form.value.images.concat(privateImages),
        description: form.value.description,
        private_note: form.value.private_note,
        condition_description: form.value.condition_description,
        retailer_email: form.value.retailer_email,
        group_sku: form.value.group_sku,
        quantity: form.value.quantity,
        currency_id: form.value.currency_id.id,
        service_type_id: form.value.service_type_id.id,
        retail_tag: form.value.retail_tag.value,
        sign_of_wear: form.value.sign_of_wear.value,
        gender: form.value.gender.value,
        age_group: form.value.age_group.value,
        tags: form.value.tags.map((tag) => parseInt(tag, 10)),
        image_validated_status: 'pending',
        material_id: form.value.material_id.id,
        model_id: form.value.model_id.id,
        custom_attributes: {
          clothing_size: form.value.clothing_size.value
        },
        change_all_product_item_price: form.value.change_all_product_item_price ? form.value.change_all_product_item_price.value : null
      };
      if (isNaN(data.tags[0]) || isUndefined(data.tags[0])) {
        delete data.tags;
      }
      store.dispatch({
        type: UPDATE_PRODUCT_REQUESTED,
        data: assign(data, { id: store.getState().Admin.Product.edit.item.id, editType: 'CONTINUE_EDITING' })
      });
    }
    $('.dynamic-submit-btn > button').css('display', 'none');
  };

  deleteItem(id) {
    if (confirm('Do you want to delete this product?')) {
      this.store.dispatch({ type: DELETE_PRODUCT_REQUESTED, data: id, queryParams: Cookies.get('queryParams') });
    }
  }
  updateMarketplaceInfo(form, id) {
    (Swal as any)
      .fire({
        confirmButtonText: 'Yes',
        title: 'Set fixed price?',
        text: 'Do you want to fix the price of the marketplace?',
        type: 'warning',
        showCancelButton: 'true',
        cancelButtonText: 'No',
        showCloseButton: 'true'
      })
      .then((result) => {
        let is_fixed_price;
        if (result.value) {
          is_fixed_price = true;
        } else if (result.dismiss === 'cancel') {
          is_fixed_price = false;
        }

        if (isBoolean(is_fixed_price)) {
          this.store.dispatch({
            type: UPDATE_MARKETPLACE_INFORMATION_REQUESTED,
            id: id,
            data: {
              partner_id: form.value.marketplace_id,
              partner_url: form.value.marketplace_url,
              partner_price: form.value.marketplace_price,
              append_description: form.value.append_description
            },
            product_id: this.productId,
            is_fixed_price: is_fixed_price
          });
        }
      });
  }

  publish(item, marketplace) {
    if (isEmpty(item.categories.data)) {
      AppInjector.get(NotificationService).show('warning', 'Category not found', 3000);
    }
    let marketplaces = [];
    marketplaces.unshift(marketplace);
    if (!isArray(marketplaces) || marketplaces.length === 0) {
      AppInjector.get(NotificationService).show('warning', 'Please select a marketplace', 3000);
    } else {
      if (item.product_managements.length === 0) {
        this.store.dispatch({ type: EDIT_PUBLISH_PRODUCT_REQUESTED, data: item, marketplaces });
      } else {
        const status_sold = find(item.product_managements, (e) => e.status === 1);
        const quantity = this.store.getState().Admin.Product.edit.item.available_quantity;
        if (status_sold && quantity === 0) {
          AppInjector.get(NotificationService).show('warning', 'Product already sold', 3000);
        } else {
          this.store.dispatch({ type: EDIT_PUBLISH_PRODUCT_REQUESTED, data: item, marketplaces });
        }
      }
    }
  }
  soldItem(id, product_management, marketplace_id, user) {
    this.sold = true;
    const modalRef = this.modalService.open(ShowUserComponent, { size: 'md' as 'sm', windowClass: 'custom-modal', centered: true });
    modalRef.componentInstance.currencies = this.store.getState().Admin.Product.edit.currencies;
    modalRef.componentInstance.marketplace = user;
    modalRef.componentInstance.order_type = ORDER_TYPES.SALE;
    modalRef.componentInstance.product_management = product_management;
    modalRef.componentInstance.product = this.store.getState().Admin.Product.edit.item;
    const product_item_managements = orderBy(
      this.store.getState().Admin.Product.edit.product_item_managements_history.filter((e) => e.user_id === marketplace_id),
      ['id']
    );
    modalRef.componentInstance.product_item_management = product_item_managements.length > 0 ? product_item_managements[0] : null;
    modalRef.result.then(
      (result) => {
        $(`.selling-success-${id}`).css('display', 'none');
        $(`.marketplaces-active-section-item`).css('display', 'none');
        $(`.marketplace-${id}`).css('display', 'block');
        this.store.dispatch({
          type: UPDATE_STATUS_PRODUCT_REQUESTED,
          id: product_management.product_id,
          data: { marketplace_id: marketplace_id, buyer_email: user.email, override_data: result }
        });
      },
      (reason) => {}
    );
  }
  editMode() {
    this.switch = true;
    $('.custom-css').css('display', 'block');
    $('.group').css('display', 'block');
    $('.table-overflow').css('display', 'none');
    $('.dynamic-submit-btn > button').css('display', 'block');
  }
  viewMode() {
    this.switch = false;
    $('.custom-css').css('display', 'none');
    $('.group').css('display', 'none');
    $('.table-overflow').css('display', 'block');
    $('.dynamic-submit-btn > button').css('display', 'none');
  }

  cancelSale(marketplace_id, product_id, product_item_id) {
    const cf = confirm('Do you want to cancel this sale?');
    if (cf) {
      this.store.dispatch({ type: CANCEL_SALE_ON_PRODUCT_REQUESTED, marketplace_id, product_id, product_item_id });
    }
  }

  delist(product_id, marketplace_id) {
    this.store.dispatch({ type: DELIST_PRODUCT_FROM_MARKETPLACE_REQUESTED, data: { product_id: product_id, marketplace_id: marketplace_id } });
  }

  markAsFail(id, product_id) {
    this.store.dispatch({ type: MARK_PRODUCT_AS_FAIL_FROM_MARKETPLACE_REQUESTED, id: id, product_id: product_id });
  }

  changeProductItemLocation(productItem, location_id) {
    productItem.current_location_id = location_id;
  }

  changeWarehouse(productItem) {
    productItem.editable = false;
    let title = '<strong>Are you sure change product item?</strong>';
    let data = assign({}, productItem, { price: Number(productItem.price) });
    if (productItem.status.slug !== 'selling' && productItem.status.slug !== 'pending') {
      this.api.admin.productItem.getItemById(productItem.id).subscribe((result) => {
        if (data.price !== result.price) {
          title =
            '<strong>Are you sure change product item? </br><span style="color: red; font-size: 12px">Warning: You can only modify the price of product items in pending or selling status!</span></strong>';
        }
        delete data.price;
        this.doUpdateProductItem(productItem, title, data);
      });
    } else {
      this.doUpdateProductItem(productItem, title, data);
    }
  }

  doUpdateProductItem(productItem, title, data) {
    (Swal as any)
      .fire({
        title: title,
        type: 'info',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Save',
        cancelButtonText: 'Cancel'
      })
      .then((result) => {
        if (result.value) {
          this.api.admin.productItem.changeLocation(productItem.id, { location_id: productItem.current_location_id }).subscribe((res) => {
            this.api.admin.productItem.update(productItem.id, data).subscribe((res1) => {
              this.api.admin.productItem.get({ product_id: res1.product_id }).subscribe((result) => {
                this.store.dispatch({ type: GET_PRODUCT_ITEM_BY_PRODUCT_ID_SUCCESSED, data: result });
              });
              AppInjector.get(NotificationService).show('success', 'Change product item success', 3000);
              const inputs = this.store.getState().Admin.Product.edit.inputs;
              this.store.dispatch({
                type: RENDER_EDIT_PRODUCT_FORM_REQUESTED,
                data: { id: res1.product_id, inputs: inputs },
                param: { includes: 'categories,seller,retailer,currency' }
              });
              this.router.navigate([]);
            });
          });
        } else {
          return false;
        }
      });
  }

  setAllWareHouse = async (form, product) => {
    if (isNil(this.warehouse)) {
      return false;
    }
    (Swal as any)
      .fire({
        title: 'Are you sure?',
        text: 'You will update all location to product items',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Ok'
      })
      .then((result) => {
        if (result.value) {
          this.api.admin.product.setAllWareHouse(product.id, { location_id: this.warehouse.id }).subscribe((res) => {
            setTimeout(() => {
              this.api.admin.productItem.get({ product_id: product.id }).subscribe((result) => {
                this.store.dispatch({ type: GET_PRODUCT_ITEM_BY_PRODUCT_ID_SUCCESSED, data: result });
              });
            }, 1300);
            AppInjector.get(NotificationService).show('success', 'Set All Warehouse to product item success', 3000);
          });
        }
      });
  };

  resolve(form, id, product_id) {
    this.store.dispatch({
      type: RESOLVE_PRODUCT_FROM_MARKETPLACE_REQUESTED,
      id: id,
      product_id: product_id,
      data: {
        partner_id: form.value.marketplace_id,
        partner_sku: form.value.marketplace_id,
        partner_url: form.value.marketplace_url,
        partner_price: form.value.marketplace_price
      }
    });
  }
  checkBoxFunction(id, value, partner_price) {
    if (value) {
      this.api.admin.productManagement.update(id, { partner_price: partner_price }).subscribe();
    } else {
      this.api.admin.productManagement.recalculatePrice(id).subscribe(() => {
        window.location.reload();
      });
    }
  }
  ngOnDestroy() {
    $('.pcoded-item>.Collection').removeClass('menu-text-color--active active');
    $('.pcoded-item>.Product').addClass('active');
    (window as any).closeReflauntSmartPricer();
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
    if (this.baseOriginalPriceObservable) {
      this.baseOriginalPriceObservable.unsubscribe();
    }
    if (this.basePayoutPriceObservable) {
      this.basePayoutPriceObservable.unsubscribe();
    }
    this.store.getState().Admin.Product.edit.is_deleted = false;
    this.store.getState().Admin.Product.edit.item = null;
  }

  disable(id) {
    const cf = confirm('Are you sure to disable this product?');
    if (cf) {
      this.api.admin.product.disable(id, {}).subscribe(
        (res) => {
          const param = { includes: 'categories,seller,retailer,currency' };
          this.store.dispatch({ type: GET_PRODUCT_REQUESTED, data: id, params: param });
          this.viewMode();
        },
        (error) => {
          AppInjector.get(NotificationService).show('warning', error.error.message, 5000);
        }
      );
    }
  }

  enable(id) {
    const cf = confirm('Are you sure to enable this product?');
    if (cf) {
      this.api.admin.product.enable(id, {}).subscribe(
        (res) => {
          const param = { includes: 'categories,seller,retailer,currency' };
          this.store.dispatch({ type: GET_PRODUCT_REQUESTED, data: id, params: param });
          this.viewMode();
        },
        (error) => {
          AppInjector.get(NotificationService).show('warning', error.error.message, 5000);
        }
      );
    }
  }

  isDisplayAskSellerButton() {
    let displayableStatus;
    const state = this.store.getState().Admin.Product.edit;
    if (state.fetched) {
      if (state.item) {
        const getConfigs = this.availableAskSellerButtonRetailers.find((e) => {
          return e.retailer === state.item.retailer.email;
        });
        if (getConfigs) {
          displayableStatus = getConfigs.status;
          if (state.item.product_items.length) {
            return displayableStatus.includes(state.item.product_items[0].status.slug);
          }
        }
      }
    }
    return false;
  }

  askSeller() {
    (Swal as any)
      .fire({
        title: '<h2>Ask seller information about:</h2>',
        html: "<p>Politely explain to the seller what information is missing on this listing</p><p>NB: Please manually translate your message to seller's language</p>",
        input: 'textarea',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Send',
        cancelButtonText: 'Cancel',
        inputValidator: (value) => {
          if (!value) {
            return 'You need to write message';
          }
        }
      })
      .then((result) => {
        if (result.value) {
          this.api.admin.product.completeListing(this.route.snapshot.params.id, { message: result.value }).subscribe((res) => {
            this.router.navigate(listRouter());
            AppInjector.get(NotificationService).show('success', 'Message is sent to seller', 5000);
          });
        }
      });
  }

  createManualOrder(marketplace, order_type = ORDER_TYPES.CANCEL) {
    const modalRef = this.modalService.open(ShowUserComponent, { size: 'md' as 'sm', windowClass: 'custom-modal', centered: true });
    modalRef.componentInstance.currencies = this.store.getState().Admin.Product.edit.currencies;
    modalRef.componentInstance.marketplace = marketplace;
    modalRef.componentInstance.order_type = order_type;
    modalRef.componentInstance.product = this.store.getState().Admin.Product.edit.item;
    const order_product_status = (orderType) => {
      switch (orderType) {
        case ORDER_TYPES.SALE:
          return ORDER_ITEM_STATUS_SOLD;
        case ORDER_TYPES.CANCEL:
          return ORDER_ITEM_STATUS_CANCELED;
        default:
          return ORDER_ITEM_STATUS_RETURN_COMPLETE;
      }
    };
    modalRef.result.then(
      (result) => {
        result.order_product_status = order_product_status(order_type);
        this.store.dispatch({
          type: CREATE_PRODUCT_ORDER_WITHOUT_STOCK_QUANTITY_REQUESTED,
          data: {
            marketplace_id: marketplace.id,
            buyer_email: marketplace.email,
            product_id: this.store.getState().Admin.Product.edit.item.id,
            override_data: result
          }
        });
      },
      (reason) => {}
    );
  }

  confirmLost(productItem) {
    (Swal as any)
      .fire({
        title: 'Are you sure confirm product lose?',
        type: 'info',
        showCloseButton: true,
        showCancelButton: true,
        focusConfirm: false,
        confirmButtonText: 'Save',
        cancelButtonText: 'Cancel'
      })
      .then((result) => {
        if (result.value) {
          this.api.admin.productItem.confirmLost(productItem.id).subscribe((res) => {
            const param = { includes: 'categories,seller,retailer,currency' };
            this.store.dispatch({ type: GET_PRODUCT_REQUESTED, data: productItem.product_id, params: param });
          });
          this.api.admin.productItem.get({ product_id: productItem.product_id }).subscribe((result) => {
            this.store.dispatch({ type: GET_PRODUCT_ITEM_BY_PRODUCT_ID_SUCCESSED, data: result });
          });
          AppInjector.get(NotificationService).show('success', 'Confirm product item lost success', 3000);
        } else {
          return false;
        }
      });
  }

  async downloadImages() {
    const idProd = this.store.getState().Admin.Product.edit.item.id;
    let zip = new JSZip();
    this.checkPending = true;
    this.api.admin.image.downloadListImage({ constraints: { imageable_id: idProd, imageable_type: 'product' } }).subscribe((res) => {
      res.forEach((fileData) => {
        zip.file(fileData.name, fileData.data, { base64: true });
      });
      zip.generateAsync({ type: 'blob' }).then(function (content) {
        saveAs(content, `product-images-${idProd}.zip`);
      });
      this.checkPending = false;
    });
  }

  sendQuote() {
    const product = this.store.getState().Admin.Product.edit.item;
    if (isNil(this.editForm) || this.editForm.controls['price'].invalid) {
      if (isNil(product.price.price) || product.original_price === '0' || product.price.price === 0) {
        (Swal as any).fire({
          title: 'Missing value?',
          text: 'Please input PAYOUT TO ORIGINAL SELLER',
          icon: 'warning',
          confirmButtonColor: '#d33',
          confirmButtonText: 'Ok'
        });
        return;
      }
    }
    const data: any = {
      id_prod: this.productId
    };
    if (!isNil(this.editForm)) {
      if (this.editForm.value.original_price) {
        data.original_price = this.editForm.value.original_price;
      }
      if (this.editForm.value.price) {
        data.price = this.editForm.value.price;
      }
      if (this.editForm.value.name) {
        data.name = this.editForm.value.name;
      }
      if (this.editForm.value.description) {
        data.description = this.editForm.value.description;
      }
    }
    this.api.admin.product.sendQuote(data).subscribe(
      (res) => {
        AppInjector.get(NotificationService).show('success', 'Send first quote success', 2000);
        setTimeout(() => {
          location.reload();
        }, 1000);
      },
      (err) => AppInjector.get(NotificationService).show('warning', err.error.message, 5000)
    );
  }
  rejectBuyback = (final?) => {
    if (final) {
      this.api.admin.product.rejectBuyback({ id_prod: this.productId, is_final: final }).subscribe((res) => {
        location.reload();
        AppInjector.get(NotificationService).show('success', 'Create payment success', 2000);
      });
    } else {
      Swal.fire({
        title: 'Do you want to reject this product?',
        showCancelButton: true,
        html: this.renderSelectCancelReason(),
        focusConfirm: false,
        preConfirm: () => {
          const reject_reason = (document.getElementById('swal-select-cancel-reason') as any).value;
          return {
            reject_reason
          };
        }
      }).then((result) => {
        let value;
        if (result.value) {
          value = true;
        } else if (result.dismiss) {
          value = false;
        }
        if (value) {
          this.api.admin.product.rejectBuyback({ id_prod: this.productId, reject_reason: result.value.reject_reason }).subscribe((res) => {
            location.reload();
            AppInjector.get(NotificationService).show('success', 'Create payment success', 2000);
          });
        }
      });
    }
  };
  sendFinalQuote() {
    const product = this.store.getState().Admin.Product.edit.item;
    const data: any = {
      id_prod: this.productId
    };
    if (!isNil(this.editForm)) {
      if (this.editForm.value.original_price) {
        data.original_price = this.editForm.value.original_price;
      }
      if (this.editForm.value.price) {
        data.price = this.editForm.value.price;
      }
      if (this.editForm.value.name) {
        data.name = this.editForm.value.name;
      }
      if (this.editForm.value.description) {
        data.description = this.editForm.value.description;
      }
    }
    this.api.admin.product.finalQuoteBuyback(data).subscribe((res) => {
      location.reload();
      AppInjector.get(NotificationService).show('success', 'Create payment success', 2000);
    });
  }

  getMinMaxRange(value) {
    const retailerService = this.store.getState().Admin.Product.edit.retailerService;
    const productPrice = Number(value);
    let lowerBound;
    let upperBound;
    if (retailerService && productPrice) {
      lowerBound = max([
        Number(productPrice - retailerService.quote_max_range_difference),
        Number((productPrice * (1 - retailerService.quote_lower_range_percentage / 100)).toFixed(2))
      ]);
      upperBound = min([
        Number(productPrice + retailerService.quote_max_range_difference),
        Number((productPrice * (1 + retailerService.quote_upper_range_percentage / 100)).toFixed(2))
      ]);
    }
    return { lowerBound, upperBound };
  }

  loadMoreItems() {
    const product_id = this.getProductId();
    const quantity = 20;
    const total = this.store.getState().Admin.Product.edit.total;
    let current_page = this.store.getState().Admin.Product.edit.current_page;
    if (current_page * quantity < total) {
      const data = {
        product_id: product_id,
        page: current_page + 1,
        ...pickBy(this.route.snapshot.queryParams, identity)
      };
      this.store.dispatch({
        type: GET_PRODUCT_ITEM_BY_PRODUCT_ID_REQUESTED,
        data: data
      });
    } else {
      this.disableLoadMoreButton = true;
    }
  }

  onLocationChange(value) {
    this.disableLoadMoreButton = false;
    this.locationIdParam = value;
    this.store.getState().Admin.Product.edit.product_items = [];
    this.router.navigate([], { queryParams: { location_id: value !== 'undefined' ? value : undefined }, queryParamsHandling: 'merge' });
  }

  onPriceChange(value) {
    this.disableLoadMoreButton = false;
    this.priceParam = value;
    this.store.getState().Admin.Product.edit.product_items = [];
    this.router.navigate([], { queryParams: { price: value !== 'undefined' ? value : undefined }, queryParamsHandling: 'merge' });
  }

  onStatusChange(value) {
    this.disableLoadMoreButton = false;
    this.statusIdParam = value;
    this.store.getState().Admin.Product.edit.product_items = [];
    this.router.navigate([], { queryParams: { status_id: value !== 'undefined' ? value : undefined }, queryParamsHandling: 'merge' });
  }

  cloneProduct = async (product_id) => {
    const retailers = this.store.getState().Admin.Product.list.retailers;
    const locations = this.store.getState().Admin.Product.edit.locations;

    const renderSelectLocation = (retailerData) => {
      let html_location = '';
      if (retailerData && retailerData.length > 0) {
        html_location += `<h4 style="text-align: left;">Clone to retailer:</h4><select id="swal2-select" class="swal2-select" style="display: flex;">`;
        retailerData.forEach((i) => {
          html_location += `<option value="${i.id}">${i.email}</option>`;
        });
        html_location += '</select>';
      }
      html_location += `<h4 style="text-align: left;">Choose location of cloned product:</h4><select id="swal2-select-location" class="swal2-select" style="display: flex;">`;
      locations.forEach((i) => {
        html_location += `<option value="${i.id}">${i.name}</option>`;
      });
      html_location += '</select>';
      return html_location;
    };
    const { value: data } = await Swal.fire({
      title: 'Clone product',
      text: 'You will clone this product?',
      icon: 'warning',
      html: renderSelectLocation(retailers),
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Confirm',
      preConfirm: () => {
        const retailer_select = document.getElementById('swal2-select');
        const retailer_id = retailer_select ? (retailer_select as any).value : null;
        if (!retailer_id) {
          return Swal.showValidationMessage('Please select retailer!');
        }
        const location_select = document.getElementById('swal2-select-location');
        const location_id = location_select ? (location_select as any).value : null;
        if (!location_id) {
          return Swal.showValidationMessage('Please select location!');
        }
        return {
          retailer_id,
          location_id
        };
      }
    });
    if (data) {
      this.store.dispatch({
        type: CLONE_PRODUCT_REQUESTED,
        data: {
          product_id,
          retailer_id: data.retailer_id,
          location_id: data.location_id
        }
      });
    }
  };

  markAsTest(product) {
    (Swal as any)
      .fire({
        confirmButtonText: 'Yes',
        title: 'Mark product is test?',
        text: 'Do you want to update product?',
        type: 'warning',
        showCancelButton: 'true',
        cancelButtonText: 'No',
        showCloseButton: 'true'
      })
      .then((result) => {
        let value;
        if (result.value) {
          value = true;
        } else if (result.dismiss === 'cancel') {
          value = false;
        }
        if (isBoolean(value)) {
          this.api.admin.product.markAsTest(product.id, { is_test: product.is_test }).subscribe((res) => {
            AppInjector.get(NotificationService).show('success', 'Update product success', 2000);
          });
        }
      });
  }

  renderSelectCancelReason = () => {
    let html = '';
    if (!isNil(this.cancelReasons)) {
      html += `<h4 style="text-align: left;">Reject reason:</h4><select id="swal-select-cancel-reason" class="swal2-select" style="display: flex;">`;
      this.cancelReasons.forEach((i) => {
        html += `<option value="${i.value}">${i.value}</option>`;
      });
      html += '</select>';
    }
    return html;
  };

  async retouchAll() {
    this.checkLoadingRetouch = true;
    const listImage = this.store.getState().Admin.Product.edit.images;
    const listId = listImage.map((image) => {
      return image.id;
    });
    this.api.image.retouchImageAll({ list_id_image: listId }).subscribe((res) => {
      this.checkLoadingRetouch = false;
      this.router.navigateByUrl('/', { skipLocationChange: true }).then(() => this.router.navigate(['/', 'admin', 'product', 'edit', this.getProductId()]));
    });
  }

  finishLater = () => {
    this.router.navigate(['/', 'admin', 'collection', 'detail', this.collectionId]);
  };

  loadMoreHistoryPush() {
    const product_id = this.getProductId();
    const { current_page, total_pages } = this.store.getState().Admin.Product.edit.product_item_managements_pagination;
    if (current_page < total_pages) {
      this.store.dispatch({
        type: GET_ALL_PRODUCT_MANAGEMENT_BY_PRODUCT_REQUESTED,
        data: {
          product_id: product_id,
          nextPage: current_page + 1,
          ...pickBy(this.route.snapshot.queryParams, identity)
        }
      });
    } else {
      this.disableLoadMoreButton = true;
    }
  }

  convertNameSize = (item) => {
    return toUpper(`${item.age_group || 'ANY AGE'} | ${item.gender || 'UNISEX'} | ${item.type || 'ANY'} | ${item.name}`);
  };
}
